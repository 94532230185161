.post-list {
  position: absolute;
  left: 50%;
  width: 70%;
  height: 100vh;
  transform: translateX(-50%);
}

.post-items {
  height: 70vh;
  overflow-y: auto;
}

.md-container {
  margin: 10px;
}

.error-message {
  color: red;
}

input {
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
}

button {
  margin: 10px;
  padding: 10px;
  color: #1cb5e0;
  border-radius: 10px;
  background-color: white;
}
